import React from 'react'

import Seo from '../components/seo'
import Layout from '../components/layout'

export default function BlogPage() {
  return (
    <Layout>
      <Seo
        title="Blog"
        description="Learn self storage tips and how to make the most of your self storage unit in Fountain, CO."
      />
      <div className="mt-20 flex justify-center">
        <h1>Coming Soon</h1>
      </div>
    </Layout>
  )
}
